export type NewOfferDto = {
    id: string
    min: number
    max: number
    productId: string | undefined
    interestRate: number
    platformFee: number
    volume: number
    selectedMin: number
    selectedMax: number
    active: boolean
    termination: Date | undefined
    individualTermination: boolean
}

// Price steps used for NOK, SEK
const PRICE_STEPS_LVL_1: NewOfferDto[] = [
    {
        id: '1',
        min: 1,
        max: 2,
        platformFee: 0.25,
        interestRate: 0.0,
        productId: undefined,
        volume: 2,
        selectedMin: 1,
        selectedMax: 2,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '2',
        min: 3,
        max: 9,
        platformFee: 0.23,
        interestRate: 0.0,
        productId: undefined,
        volume: 9,
        selectedMin: 3,
        selectedMax: 9,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '3',
        min: 10,
        max: 19,
        platformFee: 0.15,
        interestRate: 0.0,
        productId: undefined,
        volume: 19,
        selectedMin: 10,
        selectedMax: 19,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '4',
        min: 20,
        max: 49,
        platformFee: 0.13,
        interestRate: 0.0,
        productId: undefined,
        volume: 49,
        selectedMin: 20,
        selectedMax: 49,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '5',
        min: 50,
        max: 249,
        platformFee: 0.1,
        interestRate: 0.0,
        productId: undefined,
        volume: 249,
        selectedMin: 50,
        selectedMax: 249,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '6',
        min: 250,
        max: 10000,
        platformFee: 0.08,
        interestRate: 0.0,
        productId: undefined,
        volume: 1000,
        selectedMin: 250,
        selectedMax: 1000,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
]

// Price steps used for USD, EUR, CHF
const PRICE_STEPS_LVL_2: NewOfferDto[] = [
    {
        id: '1',
        min: 1,
        max: 1,
        platformFee: 0.15,
        interestRate: 0.0,
        productId: undefined,
        volume: 1,
        selectedMin: 1,
        selectedMax: 1,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '2',
        min: 2,
        max: 4,
        platformFee: 0.13,
        interestRate: 0.0,
        productId: undefined,
        volume: 4,
        selectedMin: 2,
        selectedMax: 4,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '3',
        min: 5,
        max: 24,
        platformFee: 0.1,
        interestRate: 0.0,
        productId: undefined,
        volume: 24,
        selectedMin: 5,
        selectedMax: 24,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
    {
        id: '4',
        min: 25,
        max: 10000,
        platformFee: 0.08,
        interestRate: 0.0,
        productId: undefined,
        volume: 10000,
        selectedMin: 25,
        selectedMax: 10000,
        active: false,
        termination: undefined,
        individualTermination: true,
    },
]

export const PRICING_STEPS: { [currency: string]: NewOfferDto[] } = {
    NOK: PRICE_STEPS_LVL_1,
    SEK: PRICE_STEPS_LVL_1,
    USD: PRICE_STEPS_LVL_2,
    EUR: PRICE_STEPS_LVL_2,
    CHF: PRICE_STEPS_LVL_2,
}
