import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import graphicElement from './images/supportUser-header.svg'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { Stack, Typography } from '@mui/material'
import { PURPLE, SPRING_GREEN } from '#app/colors/colors'

const SupportUsers = () => {
    const depositor = useCurrentDepositor()
    const { t } = useTranslation()

    const primaryPartnerRelation = depositor?.partnerRelations
        .filter((pr) => pr.accepted)
        .filter((pr) => pr.partnerCategory === 'SUPPORT')[0]
    const primaryAdvisor = depositor?.users.find((user) => user.id === primaryPartnerRelation?.primaryAdvisor)

    const employeeMeta = (lastName) => {
        if (lastName.endsWith('Landsem')) {
            return {
                title: 'Head of Sales and Customer Service',
            }
        }
        if (lastName.endsWith('Ellingsen')) {
            return {
                title: 'CEO',
            }
        }
        if (lastName.endsWith('Bruvik')) {
            return {
                title: 'CEO, Fixrate Capital',
            }
        }
        if (lastName.endsWith('Bahnsen')) {
            return {
                title: 'Key Account Manager',
            }
        }
        if (lastName.endsWith('Nyhus')) {
            return {
                title: 'Sales Director Bank',
            }
        }
        if (lastName.endsWith('Sotberg')) {
            return {
                title: 'Business Controller',
            }
        }
        if (lastName.endsWith('Misfjord')) {
            return {
                title: 'Partner Manager',
            }
        }
        if (lastName.endsWith('Veie')) {
            return {
                title: 'Customer Success Manager',
            }
        }
        if (lastName.endsWith('Braathen')) {
            return {
                title: 'Sales Director',
            }
        }
        if (lastName.endsWith('Olausson')) {
            return {
                title: 'Key Account Manager',
            }
        }
        if (lastName.endsWith('Karlsen')) {
            return {
                title: 'Customer Success Manager',
            }
        }
        if (lastName.endsWith('Ljung')) {
            return {
                title: 'VD/Director Business Development - Sweden',
            }
        }
        if (lastName.endsWith('Bölin')) {
            return {
                title: 'Senior Consultant Business Development - Sweden',
            }
        }
        return {
            title: 'Teknisk hjelp',
        }
    }

    const formatPhoneNumber = (number, countryCode) => {
        let formattedNumber = number
        const phoneNumberPrefix = countryCode === 'SE' ? '+46' : '+47'

        //Check for country code and remove it from number
        if (number.indexOf(phoneNumberPrefix) !== -1) {
            formattedNumber = formattedNumber.replace(phoneNumberPrefix, '')
        }

        formattedNumber = formattedNumber.replace(/\D/g, '') // Remove non-numeric characters

        if (countryCode === 'SE') {
            formattedNumber = formattedNumber.match(/^(\d{2,3})(\d{2})(\d{2})(\d{2})?$/)
            if (formattedNumber) {
                formattedNumber = formattedNumber.slice(1).filter(Boolean).join(' ')
            }
        } else {
            formattedNumber = formattedNumber.match(/.{1,2}/g).join(' ')
        }

        return phoneNumberPrefix + ' ' + formattedNumber
    }

    const WrapperElement = () => {
        if (depositor && primaryAdvisor) {
            return (
                <Stack>
                    <img src={graphicElement} alt={''} style={{ marginBottom: '-0.1rem' }} />
                    <Stack sx={{ backgroundColor: PURPLE[800] }}>
                        <Stack spacing={2} sx={{ padding: '5rem' }}>
                            <Typography sx={{ color: SPRING_GREEN[500] }}>
                                {t('pages-organizations.fixrateContacts')}
                            </Typography>
                            <Stack spacing={1}>
                                <Stack>
                                    <Typography variant="h3" sx={{ color: PURPLE[50] }}>
                                        {primaryAdvisor.firstName} {primaryAdvisor.lastName}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[50] }}>
                                        {employeeMeta(primaryAdvisor.lastName).title}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography sx={{ color: PURPLE[50] }}>
                                        <i className="ri-mail-line" />
                                        {primaryAdvisor.email}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[50] }}>
                                        <i className="ri-smartphone-line" />
                                        {formatPhoneNumber(primaryAdvisor.phone, primaryAdvisor.countryCode)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )
        } else {
            return null
        }
    }

    return <Fragment>{primaryAdvisor && <WrapperElement />}</Fragment>
}

export default SupportUsers
