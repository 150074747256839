import { COLORS } from '#app/colors/colors'
import useTableSort from '#app/services/useTableSort'
import { CurrencyOutput, InterestOutput } from '#components'
import { useCurrencyOutput } from '#components/CurrencyOutput/useCurrencyOutput'
import { FundLogo } from '#pages/FundDetails/FundLogo/FundLogo'
import * as selectors from '#state/selectors'
import { FundPlacementRiskRowData, FundPortfolioRiskData } from '#state/selectors'
import { useSelector } from '#state/useSelector'
import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

export function FundPortfolioRiskTable() {
    const { t } = useTranslation()
    const rows = useSelector((state) => selectors.fundPlacementsRiskRowDataSelector(state))
    const { sortedRows } = useTableSort<FundPlacementRiskRowData>(rows, 'marketValue', 'desc', 'fullFundName', 'asc')
    const portfolioRisk = useSelector(selectors.fundPortfolioRiskDataSelector)

    return (
        <Stack direction={'column'} spacing={2}>
            <h3>
                <i className="ri-microscope-line" />
                <span>{t('pages-FundOverview.risk')}</span>
            </h3>
            <TableContainer component={Paper}>
                <Table aria-label={'Fund portfolio risk table'}>
                    <TableHead>
                        <TableRow>
                            <TableCell /> {/* Empty header cell, body cells will contain fund logo. */}
                            <TableCell>
                                <Typography fontSize={'1.4rem'} color={COLORS['SILVER_GRAY']} fontWeight={600}>
                                    {t('pages-FundPortfolioRisk.fundName')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontSize={'1.4rem'} color={COLORS['SILVER_GRAY']} fontWeight={600}>
                                    {t('pages-FundPortfolioRisk.interestRateSensitivity')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontSize={'1.4rem'} color={COLORS['SILVER_GRAY']} fontWeight={600}>
                                    {t('pages-FundPortfolioRisk.creditSensitivity')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontSize={'1.4rem'} color={COLORS['SILVER_GRAY']} fontWeight={600}>
                                    {t('pages-FundPortfolioRisk.standardDeviation3Y')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontSize={'1.4rem'} color={COLORS['SILVER_GRAY']} fontWeight={600}>
                                    {t('pages-FundPortfolioRisk.marketValue')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontSize={'1.4rem'} color={COLORS['SILVER_GRAY']} fontWeight={600}>
                                    {t('pages-FundPortfolioRisk.portfolioWeight')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.map((row) => (
                            <RiskRow key={row.fundPlacementId} row={row} />
                        ))}
                        {portfolioRisk && <PortfolioRiskRow portfolioRisk={portfolioRisk} />}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

function RiskRow({ row }: { row: FundPlacementRiskRowData }) {
    const Currency = useCurrencyOutput()

    return (
        <TableRow>
            <TableCell>
                <Box sx={{ '& img': { width: '100%' } }} width={'10rem'} display="flex" justifyContent="flex-end">
                    <FundLogo fundName={row.fullFundName} />
                </Box>
            </TableCell>
            <TableCell>
                <Typography fontSize={'1.4rem'} fontWeight={500} sx={{ maxWidth: '15rem', overflow: 'auto' }}>
                    {row.fullFundName != null ? row.fullFundName : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={'1.4rem'}>
                    {row.interestRateSensitivity != null
                        ? CurrencyOutput.formatNoCode(row.interestRateSensitivity, 2)
                        : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={'1.4rem'}>
                    {row.creditSensitivity != null ? CurrencyOutput.formatNoCode(row.creditSensitivity, 2) : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={'1.4rem'}>
                    {row.standardDeviation != null ? InterestOutput.format(row.standardDeviation) : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={'1.4rem'}>{Currency(row.marketValue, { decimals: 0 })}</Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={'1.4rem'}>
                    {row.portfolioWeight != null ? InterestOutput.format(row.portfolioWeight) : '-'}
                </Typography>
            </TableCell>
        </TableRow>
    )
}

function PortfolioRiskRow({ portfolioRisk }: { portfolioRisk: FundPortfolioRiskData }) {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()

    return (
        <TableRow
            sx={{
                '& td': {
                    borderTop: `2px solid ${COLORS['SILVER_GRAY']}`,
                },
            }}
        >
            <TableCell />
            <TableCell>
                <Typography fontWeight={600}>{t('pages-FundPortfolioRisk.portfolio')}</Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>
                    {portfolioRisk.interestRateSensitivity != null
                        ? CurrencyOutput.formatNoCode(portfolioRisk.interestRateSensitivity, 2)
                        : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>
                    {portfolioRisk.creditSensitivity != null
                        ? CurrencyOutput.formatNoCode(portfolioRisk.creditSensitivity, 2)
                        : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>
                    {portfolioRisk.standardDeviation != null
                        ? InterestOutput.format(portfolioRisk.standardDeviation)
                        : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>{Currency(portfolioRisk.totalValue, { decimals: 0 })}</Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>{InterestOutput.format(portfolioRisk.totalWeight) ?? 0}</Typography>
            </TableCell>
            <TableCell />
        </TableRow>
    )
}
