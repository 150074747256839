import { useTranslation } from '#app/components/i18n'
import { useCommand } from '#app/services/beta'
import { sendExceptionToSentry } from '#app/services/sentry'
import { showConfirmationModal } from '#app/state/reducers/confirmationModal'
import { useSelector } from '#app/state/useSelector'
import * as selectors from '#state/selectors'
import format from 'date-fns/format'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NewOfferDto } from './PricingSteps'

type SubmitOffers = {
    offers?: NewOfferDto[]
    defaultProductId?: string
    selectedDepositorValue?: string
    urlContext?: string
    termination?: string
    individualTermination?: boolean
    setPublishing: (boolean: boolean) => void
}

export default function useSubmitOffers() {
    const dispatch = useDispatch()
    const { createAd } = useCommand()
    const navigate = useNavigate()
    const bank = useSelector((state) => selectors.bankInSession(state))
    const selectedCurrency = useSelector((state) => state.session?.association?.currentCurrency)
    const products = useSelector((state) => state.products)
    const { t } = useTranslation('pages-BankAds')

    return async function submitOffers({
        offers,
        defaultProductId,
        selectedDepositorValue,
        urlContext,
        setPublishing,
    }: SubmitOffers) {
        const selectedOffers = [...offers].filter((offer) => offer.active)
        const validOffers = selectedOffers.filter(
            (offer) =>
                offer.selectedMin &&
                offer.selectedMax &&
                offer.volume &&
                offer.selectedMin >= offer.min &&
                offer.selectedMin <= offer.selectedMax &&
                offer.selectedMax <= offer.max &&
                offer.selectedMax <= offer.volume
        )

        if (validOffers.length === selectedOffers.length && selectedOffers.length !== 0) {
            setPublishing(true)
            await Promise.all(
                await selectedOffers.map(async (offer) => {
                    return await createAd({
                        bankId: bank.id,
                        product: offer.productId ? offer.productId : defaultProductId,
                        interest: parseFloat(offer.interestRate.toFixed(2)),
                        min: offer.selectedMin,
                        max: offer.selectedMax,
                        total: offer.volume,
                        depositorId: selectedDepositorValue && selectedDepositorValue,
                        termination:
                            offer.individualTermination ||
                            products[offer.productId]?.termsType === 'RECURRING_FIXED_TERMS'
                                ? null
                                : format(offer.termination, 'yyyy-MM-dd'),
                        individualTermination: offer.individualTermination,
                        currency: selectedCurrency,
                    })
                })
            )
                .then(() => {
                    dispatch(
                        showConfirmationModal({
                            title: t('adCreatedTitle'),
                            text: t('adCreatedText'),
                            buttonText: t('common.continue'),
                        })
                    )
                    navigate(urlContext)
                })
                .catch((e) => {
                    sendExceptionToSentry(e, {
                        message: 'Failed to create ad',
                        offers: offers,
                        defaultProductId: defaultProductId,
                        selectedDepositorValue: selectedDepositorValue,
                    })
                })
                .finally(() => {
                    setPublishing(false)
                })
        } else {
            setPublishing(false)
        }
    }
}
