import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './NewAdModals.module.scss'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import AdCard from '../marketplace/AdCard/AdCard'
import { DateOutput, InterestOutput, NumberInput } from '#app/components'
import { useSelector } from '#app/state/useSelector'
import { bankInSession, isFixrateUser } from '#app/state/selectors'
import { useTranslation } from 'react-i18next'
import { NewOfferDto } from './PricingSteps'
import { useBankCalendar } from '#app/services/useBankCalendar'
import addMonths from 'date-fns/addMonths'
import { Currency, InterestRateBenchmark, Product, RegulatoryRegion } from '@fixrate/fixrate-query'
import { defaultBenchmark } from '#services/interestRateBenchmark'
import { getAllOrders, getLastFullySubscribedAds, getLastOrdersByCategory } from '#services/thunks/statistics'
import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import NewAdMarketplaceData from './NewAdMarketPlaceData'
import { FullySubscribedAd } from '../BankDashboard/components/FullySubscribedAds'
import useCurrentCountryCode from '#services/useCurrentCountryCode'
import FXDateInput from '#app/components/DateInput/FXDateInput'
import { useQuery } from 'react-query'

type Props = {
    offers: NewOfferDto[]
    setOffers: (offers: NewOfferDto[]) => void
    defaultProductId: string
    marketplaceOver20: boolean
}

interface LastOrdersByCategory {
    business: string
    created: string
    interest: number
    benchmarkInterest: number
    interestRateBenchmark: InterestRateBenchmark
    productId: string
    totalAssets: number
    volume: number
    max: number
    min: number
    interestType: string
    regulatoryRegion: RegulatoryRegion
    currency: Currency
}

export interface AllOrders {
    created: string
    interest: number
    benchmarkInterest: number
    interestRateBenchmark: InterestRateBenchmark
    volume: number
    adId: string
    regulatoryRegion: RegulatoryRegion
    currency: Currency
}

type ProductMap = { [key: string]: Product }

export default function NewOfferAccordion({ offers, setOffers, defaultProductId, marketplaceOver20 }: Props) {
    const { t } = useTranslation()
    const bank = useSelector(bankInSession)
    const regulatoryRegion = useCurrentCountryCode()
    const ads = useSelector((state) => state.ads)
    const banks = useSelector((state) => state.banks)
    const isFixedInterest = useSelector((state) => state.products[defaultProductId]?.type === 'FIXED')
    const products: ProductMap = useSelector((state) => {
        const showSpecialOffers = isFixrateUser(state)
        return Object.values(state.products)
            .filter((product) => product?.visibility !== 'NONE')
            .filter((product) => showSpecialOffers || product?.visibility !== 'SPECIAL_OFFER')
            .filter((product) =>
                isFixedInterest ? product?.termsType === 'FIXED_TERMS' : product?.termsType === 'NOTICE'
            )
            .filter((product) => product?.regulatoryRegions.includes(regulatoryRegion))
            .reduce((acc, product) => {
                acc[product?.id] = product
                return acc
            }, {})
    })
    const benchmarkInterestRate = useSelector((state) => state.interestRateBenchmarks.defaultBenchmarkInterestRate)
    const { nextBankDay, isValidBankDay } = useBankCalendar()
    const currency = useSelector((state) => state.session?.association?.currentCurrency)
    const interestRateBenchmark = useSelector((state) => defaultBenchmark(state.session?.association?.currentCurrency))
    const dispatch =
        useDispatch<(arg0: (any) => void) => Promise<LastOrdersByCategory[] | FullySubscribedAd[] | AllOrders[]>>()

    const { data: orders } = useQuery(
        'lastOrdersByCategory',
        async () => (await dispatch(getLastOrdersByCategory())) as LastOrdersByCategory[],
        { initialData: [] }
    )
    const { data: allOrders } = useQuery('allOrders', async () => (await dispatch(getAllOrders())) as AllOrders[], {
        initialData: [],
    })
    const { data: fullySubscribedAds } = useQuery(
        'fullySubscribedAds',
        async () => (await dispatch(getLastFullySubscribedAds())) as FullySubscribedAd[],
        { initialData: [] }
    )

    const toggleActiveOffer = (id: string) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return { ...o, active: !o.active }
        })
        setOffers(newOffers)
    }

    const setMin = (id: string, value: number) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return { ...o, selectedMin: value }
        })
        setOffers(newOffers)
    }

    const setMax = (id: string, value: number) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return { ...o, selectedMax: value }
        })
        setOffers(newOffers)
    }

    const setVolume = (id: string, value: number) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return { ...o, volume: value }
        })
        setOffers(newOffers)
    }

    const setInterest = (id: string, value: number) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return { ...o, interestRate: +value.toFixed(2) }
        })
        setOffers(newOffers)
    }

    const setProduct = (id: string, value: string) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return { ...o, productId: value, termination: isFixedInterest ? getTerminationDate(value) : null }
        })
        setOffers(newOffers)
    }

    const setTermination = (id: string, value: Date) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return { ...o, termination: value }
        })
        setOffers(newOffers)
    }

    const setIndividualTermination = (id: string, value: boolean) => {
        const newOffers = offers.map((o) => {
            if (o.id !== id) {
                return o
            }
            return {
                ...o,
                individualTermination: value,
                termination: !value ? getTerminationDate(o.productId ? o.productId : defaultProductId) : null,
            }
        })
        setOffers(newOffers)
    }

    const getTerminationDate = (productId: string) => {
        const terminationDate = addMonths(new Date(), products[productId]?.months)
        return nextBankDay(terminationDate)
    }

    return (
        <>
            {offers
                .filter((o) => (marketplaceOver20 ? o.min >= 20 : o.min < 20))
                .map((offer) => {
                    const interestRate = isFixedInterest
                        ? offer.interestRate
                        : offer.interestRate + (benchmarkInterestRate || 0)

                    const productIds = products ? Object.keys(products) : []
                    const lastOrder = orders
                        .filter((order) =>
                            isFixedInterest ? order.interestType === 'FIXED' : order.interestType === 'FLOATING'
                        )
                        .filter((order) => order.min === offer.min && order.max === offer.max)[0]

                    const adsSameCategory = ads
                        .filter((ad) => productIds.includes(ad.productId))
                        .filter((ad) => ad.min >= offer.min && ad.max <= offer.max && ad.deactivatedBy === null)
                    const highestInterestAd = adsSameCategory.sort((a, b) => b.interest - a.interest)[0]

                    return (
                        <Accordion
                            data-cy={'offer-step-' + offer.id}
                            key={offer.id}
                            onChange={() => toggleActiveOffer(offer.id)}
                            className={styles.adAccordion}
                            expanded={offer.active}
                        >
                            <AccordionSummary sx={{ justifyContent: 'space-between' }}>
                                <div className="flex" style={{ width: '100%' }}>
                                    <div className={styles.columnsContainer}>
                                        <Switch checked={offer.active} />
                                        <div className={styles.titleContainer}>
                                            <h3 className={styles.title}>
                                                {offer.min}-{offer.max} {t('pages-BankAds.millions')}
                                            </h3>
                                            <p>
                                                {t('pages-BankAds.minMaxDetails', { min: offer.min, max: offer.max })}
                                            </p>
                                        </div>

                                        <div className={styles.rightContainer}>
                                            <Box
                                                sx={{
                                                    textAlign: 'center',
                                                    width: '7rem',
                                                    padding: '0.6rem 1rem',
                                                    borderRadius: '3px',
                                                    backgroundColor: SPRING_GREEN[100],
                                                    color: PURPLE[800],
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {highestInterestAd
                                                    ? `${parseFloat(highestInterestAd.interest.toFixed(2))}%`
                                                    : '-'}
                                            </Box>
                                            <Box
                                                sx={{
                                                    textAlign: 'center',
                                                    width: '7rem',
                                                    padding: '0.6rem 1rem',
                                                    borderRadius: '3px',
                                                    backgroundColor: PURPLE[50],
                                                    color: PURPLE[800],
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {lastOrder ? (
                                                    <Tooltip
                                                        arrow
                                                        title={
                                                            <Box display="flex" flexDirection="column">
                                                                <span>{`${t('pages-BankAds.volumeHeader')}: ${lastOrder.volume}`}</span>
                                                                <span>{`${t('pages-BankAds.productHeader')}: ${t(`common.productLongName${lastOrder.productId}`)}`}</span>
                                                                <span>
                                                                    {`${t('pages-BankAds.date')}: `}
                                                                    <DateOutput.Date date={lastOrder.created} />
                                                                </span>
                                                            </Box>
                                                        }
                                                    >
                                                        <div>{parseFloat(lastOrder.interest.toFixed(2))}%</div>
                                                    </Tooltip>
                                                ) : (
                                                    '-'
                                                )}
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                className={styles.accordionDetails}
                                sx={{ padding: '4rem 6rem', backgroundColor: '#f1f1f1', position: 'relative' }}
                            >
                                <Grid container height="100%">
                                    <Grid item xs={12} lg={5}>
                                        <AdCard
                                            product={products[offer.productId ?? defaultProductId]}
                                            className={styles.previewCard}
                                            ad={{
                                                interest: interestRate,
                                                nominalInterestRate: offer.interestRate,
                                                min: offer.selectedMin,
                                                max: offer.selectedMax,
                                                remaining: offer.volume,
                                                productId: offer.productId ? offer.productId : defaultProductId,
                                                bankId: bank.id,
                                                adTag: null,
                                                depositorId: null,
                                                openOrders: [],
                                                interestRateBenchmark: interestRateBenchmark,
                                                validity: null,
                                            }}
                                            onAdClick={null}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Grid container>
                                            <Grid item xs={12} sm={5}>
                                                <label className={styles.label}>
                                                    <span>{t('pages-BankAds.minVolumeHeader')}</span>
                                                    <Tooltip arrow title={t('pages-BankAds.minVolumeHelpText')}>
                                                        <i className="ri-information-line purple" />
                                                    </Tooltip>
                                                </label>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <NumberInput
                                                        sx={{
                                                            '& .MuiInputBase-input': { color: PURPLE[800] },
                                                            '& .MuiInputBase-root': {
                                                                height: '4.7rem',
                                                            },
                                                        }}
                                                        data-cy="smallestDepositInput"
                                                        className={styles.volumeInput}
                                                        value={offer.selectedMin}
                                                        onChange={(value) => setMin(offer.id, value)}
                                                    />
                                                    <span className={styles.unit}>
                                                        {t('common.million_other', { currency })}
                                                    </span>
                                                </Box>
                                                {offer.selectedMin < offer.min && (
                                                    <p className="field-error-message" style={{ fontSize: '1.2rem' }}>
                                                        {t('pages-BankAds.minimumWarning', { min: offer.min })}{' '}
                                                        {t('common.million_other', { currency })}{' '}
                                                    </p>
                                                )}
                                                {offer.selectedMax && offer.selectedMin > offer.selectedMax && (
                                                    <p className="field-error-message" style={{ fontSize: '1.2rem' }}>
                                                        {t('pages-BankAds.minimumOverMaxWarning')}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={7}>
                                                <label className={styles.label}>
                                                    <span>{t('pages-BankAds.maxVolumeHeader')}</span>
                                                    <Tooltip arrow title={t('pages-BankAds.maxVolumeHelpText')}>
                                                        <i className="ri-information-line purple" />
                                                    </Tooltip>
                                                </label>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <NumberInput
                                                        sx={{
                                                            '& .MuiInputBase-input': { color: PURPLE[800] },
                                                            '& .MuiInputBase-root': {
                                                                height: '4.7rem',
                                                            },
                                                        }}
                                                        data-cy="largestDepositInput"
                                                        className={styles.volumeInput}
                                                        value={offer.selectedMax}
                                                        onChange={(value) => setMax(offer.id, value)}
                                                    />
                                                    <span className={styles.unit}>
                                                        {t('common.million_other', { currency })}
                                                    </span>
                                                </Box>
                                                {offer.selectedMax > offer.max && (
                                                    <p className="field-error-message" style={{ fontSize: '1.2rem' }}>
                                                        {t('pages-BankAds.maxWarning', { max: offer.max })}{' '}
                                                        {t('common.million_other', { currency })}{' '}
                                                    </p>
                                                )}
                                                {offer.selectedMax < offer.min && (
                                                    <p className="field-error-message" style={{ fontSize: '1.2rem' }}>
                                                        {t('pages-BankAds.maxBelowMinimumWarning', { min: offer.min })}{' '}
                                                        {t('common.million_other', { currency })}{' '}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <label className={styles.label}>
                                                    <span>{t('pages-BankAds.totalDemandHeader')}</span>
                                                    <Tooltip arrow title={t('pages-BankAds.totalDemandHelpText')}>
                                                        <i className="ri-information-line purple" />
                                                    </Tooltip>
                                                </label>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <NumberInput
                                                        sx={{
                                                            '& .MuiInputBase-input': { color: PURPLE[800] },
                                                            '& .MuiInputBase-root': {
                                                                height: '4.7rem',
                                                            },
                                                        }}
                                                        data-cy="volumeInput"
                                                        className={styles.volumeInput}
                                                        value={offer.volume}
                                                        onChange={(value) => setVolume(offer.id, value)}
                                                    />
                                                    <span className={styles.unit}>
                                                        {t('common.million_other', { currency })}
                                                    </span>
                                                </Box>
                                                {(offer.volume < offer.selectedMax || !offer.volume) && (
                                                    <p className="field-error-message" style={{ fontSize: '1.2rem' }}>
                                                        {t('pages-BankAds.totalVolumeWarning')}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={7}>
                                                <label className={styles.label}>
                                                    <span>
                                                        {isFixedInterest
                                                            ? t('pages-BankAds.durationHeader')
                                                            : t('pages-BankAds.noticePeriodHeader')}
                                                    </span>
                                                    <Tooltip
                                                        arrow
                                                        title={
                                                            isFixedInterest
                                                                ? t('pages-BankAds.durationHelpText')
                                                                : t('pages-BankAds.noticePeriodHelpText')
                                                        }
                                                    >
                                                        <i className="ri-information-line purple" />
                                                    </Tooltip>
                                                </label>
                                                <Select
                                                    name="product"
                                                    data-cy="productSelect"
                                                    defaultValue={products[0]?.id}
                                                    value={offer.productId ?? defaultProductId}
                                                    onChange={(e) => setProduct(offer.id, e.target.value)}
                                                    autoFocus
                                                    sx={{ width: '12rem' }}
                                                >
                                                    {Object.values(products).map((product) => (
                                                        <MenuItem
                                                            data-cy={'productSelectItem-' + product?.name}
                                                            key={product?.id}
                                                            value={product?.id}
                                                        >
                                                            {t('common.productLongName' + product?.id)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <label className={styles.label}>
                                                    <span>
                                                        {isFixedInterest
                                                            ? t('pages-BankAds.fixedInterest')
                                                            : t('pages-BankAds.marginAddition')}
                                                    </span>
                                                    <Tooltip
                                                        arrow
                                                        title={
                                                            isFixedInterest
                                                                ? t('pages-BankAds.fixedInterestHelpText')
                                                                : t('pages-BankAds.marginHelpText', {
                                                                      base: InterestOutput.benchmarkLabel(
                                                                          interestRateBenchmark,
                                                                          t
                                                                      ),
                                                                  })
                                                        }
                                                    >
                                                        <i className="ri-information-line purple" />
                                                    </Tooltip>
                                                </label>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <NumberInput
                                                        sx={{
                                                            '& .MuiInputBase-input': { color: PURPLE[800] },
                                                            '& .MuiInputBase-root': {
                                                                height: '4.7rem',
                                                            },
                                                        }}
                                                        formatFn={(value) => InterestOutput.format(value.toFixed(2))}
                                                        data-cy="interestInput"
                                                        className={styles.interestInput}
                                                        value={offer.interestRate}
                                                        onChange={(value) =>
                                                            value === null
                                                                ? setInterest(offer.id, 0.0)
                                                                : setInterest(offer.id, value)
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                            {isFixedInterest && (
                                                <Grid item md={12}>
                                                    <Stack>
                                                        <FormControl>
                                                            <FormLabel
                                                                className={styles.label}
                                                                id={'terminationDate-label-' + offer.id}
                                                            >
                                                                {t('pages-BankAds.selectSettlementDate')}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                aria-labelledby={'terminationDate-label-' + offer.id}
                                                                name={'terminationDate-group-' + offer.id}
                                                                value={offer.individualTermination ? 'true' : 'false'}
                                                                onChange={(e) =>
                                                                    setIndividualTermination(
                                                                        offer.id,
                                                                        e.target.value === 'true'
                                                                    )
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    sx={{
                                                                        marginBottom: 0,
                                                                        '& .MuiTypography-root': { fontSize: '1.4rem' },
                                                                    }}
                                                                    disabled={
                                                                        products[offer.productId]?.termsType ===
                                                                        'RECURRING_FIXED_TERMS'
                                                                    }
                                                                    value="true"
                                                                    control={<Radio />}
                                                                    label={
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                gap: 0.5,
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                {t(
                                                                                    'pages-BankAds.automaticTerminationDate'
                                                                                )}
                                                                            </span>
                                                                            <Tooltip
                                                                                arrow
                                                                                title={t(
                                                                                    'pages-BankAds.automaticTerminationDateInfo'
                                                                                )}
                                                                            >
                                                                                <i className="ri-information-line purple" />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                />
                                                                <FormControlLabel
                                                                    sx={{
                                                                        '& .MuiTypography-root': { fontSize: '1.4rem' },
                                                                    }}
                                                                    disabled={
                                                                        products[offer.productId]?.termsType ===
                                                                        'RECURRING_FIXED_TERMS'
                                                                    }
                                                                    value="false"
                                                                    control={<Radio />}
                                                                    label={t('pages-BankAds.givenTerminationDate')}
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        {!offer.individualTermination && (
                                                            <FXDateInput
                                                                value={offer.termination}
                                                                onChange={(date) => setTermination(offer.id, date)}
                                                                onlyBankDays
                                                            />
                                                        )}
                                                    </Stack>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} mt={2}>
                                        <Typography
                                            variant={'h3'}
                                            sx={{
                                                mt: '1em',
                                                mb: '0.5em',
                                                color: PURPLE[800],
                                            }}
                                        >
                                            {t('pages-BankAds.bankTotalCost')}
                                        </Typography>
                                        <TotalCostTable
                                            offer={offer}
                                            interestRateBenchmark={interestRateBenchmark}
                                            benchmarkInterestRate={benchmarkInterestRate}
                                            fixedInterest={isFixedInterest}
                                        />
                                    </Grid>

                                    <NewAdMarketplaceData
                                        offer={offer}
                                        banks={banks}
                                        allOrders={allOrders}
                                        adsSameCategory={adsSameCategory}
                                        fullySubscribedAds={fullySubscribedAds}
                                        products={products}
                                    />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
        </>
    )
}

const TotalCostTable = ({
    offer,
    interestRateBenchmark,
    benchmarkInterestRate,
    fixedInterest,
}: {
    offer: NewOfferDto
    interestRateBenchmark: InterestRateBenchmark
    benchmarkInterestRate: number
    fixedInterest?: boolean
}) => {
    const { t } = useTranslation()

    const totalCost = fixedInterest
        ? offer.interestRate + offer.platformFee
        : benchmarkInterestRate + offer.interestRate + offer.platformFee

    return (
        <TableContainer component={Paper} sx={{ padding: '0.8em', borderRadius: '0.2em' }}>
            <Table>
                <TableBody sx={{ color: PURPLE[800] }}>
                    {!fixedInterest && (
                        <TableRow>
                            <TableCell>{InterestOutput.benchmarkLabel(interestRateBenchmark, t)}</TableCell>
                            <TableCell align={'right'}>{InterestOutput.format(benchmarkInterestRate)}</TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            {fixedInterest ? t('pages-BankAds.fixedInterest') : t('pages-BankAds.marginAddition')}
                        </TableCell>
                        <TableCell align={'right'}>
                            {(fixedInterest ? '' : '+') + InterestOutput.format(offer.interestRate)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottom: '1px solid black' }}>{t('pages-BankAds.platformFee')}</TableCell>
                        <TableCell align={'right'} sx={{ borderBottom: '1px solid', borderBottomColor: PURPLE[800] }}>
                            +{InterestOutput.format(offer.platformFee)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            sx={{
                                fontWeight: '700',
                                borderBottom: 'none',
                            }}
                        >
                            {t('pages-BankAds.bankTotalCost')}
                        </TableCell>
                        <TableCell
                            align={'right'}
                            sx={{
                                fontWeight: '700',
                                borderBottom: 'none',
                            }}
                        >
                            {InterestOutput.format(totalCost)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
